import dayjs from 'dayjs'
import { jsGetAge, jsGetworkedDate } from '@/libs/util'

export const data12 = [
  {
    title: '序号',
    type: 'index',
    width: 60,
    align: 'center',
    width: "80"
  },
  {
    title: '姓名',
    key: 'personal_name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? params.row.personal.name : ""}</span>;
    },
  },
  {
    title: '投递时间',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{dayjs(params.row.entryDate).format("YYYY-MM-DD")}</span>;
    },
  },
  {
    title: '性别',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? params.row.personal.sex == 1 ? '男' : '女' : ''}</span>;
    },
  },
  {
    title: '工作经验',
    key: 'workYear',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? jsGetworkedDate(params.row.personal.workedDate) : ''}</span>;
    },
  },
  {
    title: '年龄',
    key: 'age',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? jsGetAge(params.row.personal.birthDate) : ''}</span>;
    },
  },
  {
    title: '学历',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? params.row.personal.education : ''}</span>;
    },
  },
  {
    title: '投递岗位',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.position ? params.row.position.name : ""}</span>;
    },

  },
  {
    title: '简历详情',
    slot: 'details',
    align: 'center',
    width: "180"
  },

]


export const data3 = [
  {
    title: '序号',
    type: 'index',
    width: 60,
    align: 'center',
    width: "80"
  },
  {
    title: '姓名',
    key: 'personal_name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? params.row.personal.name : ""}</span>;
    },
  },
  {
    title: '投递时间',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{dayjs(params.row.entryDate).format("YYYY-MM-DD")}</span>;
    },
  },
  {
    title: '性别',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? params.row.personal.sex == 1 ? '男' : '女' : ''}</span>;
    },
  },
  {
    title: '工作经验',
    key: 'workYear',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? jsGetworkedDate(params.row.personal.workedDate) : ''}</span>;
    },
  },
  {
    title: '年龄',
    key: 'age',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? jsGetAge(params.row.personal.birthDate) : ''}</span>;
    },
  },
  {
    title: '学历',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.personal ? params.row.personal.education : ''}</span>;
    },
  },
  {
    title: '投递岗位',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.position ? params.row.position.name : ""}</span>;
    },

  },
  {
    title: '面试邀约',
    slot: 'invite',
    align: 'center',
    width: "180"
  },
  {
    title: '简历详情',
    slot: 'details',
    align: 'center',
    width: "180"
  },

]

export const data4 = [
  {
    title: '序号',
    type: 'index',
    width: 60,
    align: 'center',
    width: "80"
  },
  {
    title: '姓名',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.cllectList[0] ? params.row.cllectList[0].name : ''}</span>;
    },
  },
  {
    title: '投递时间',
    key: 'name',
    align: 'center',
    render: (h, params) => {
      return <span>{dayjs(params.row.time).format("YYYY-MM-DD")}</span>;
    },
  },
  {
    title: '性别',
    key: 'sex',
    render: (h, params) => {
      return <span>{params.row.cllectList[0] ? (params.row.cllectList[0].sex == 1 ? '男' : '女') : ""}</span>;
    },
    align: 'center',
  },
  {
    title: '工作经验',
    key: 'workYear',
    render: (h, params) => {
      return <span>{params.row.cllectList[0] ? jsGetworkedDate(params.row.cllectList[0].workedDate) : ''}</span>;
    },
    align: 'center',
  },
  {
    title: '年龄',
    key: 'age',
    align: 'center',
    render: (h, params) => {
      return <span>{params.row.cllectList[0] ? jsGetAge(params.row.cllectList[0].birthDate) : ''}</span>;
    },
  },

  {
    title: '学历',
    key: 'education',
    render: (h, params) => {
      return <span>{params.row.cllectList[0] ? params.row.cllectList[0].education : ''}</span>;
    },
    align: 'center',
  },

  {
    title: '投递岗位',
    key: 'position',
    render: (h, params) => {
      return <span>{params.row.position ? params.row.position.name : ''}</span>;
    },
    align: 'center',
  },

  {
    title: '简历详情',
    slot: 'details',
    align: 'center',
    width: "180"
  },



]